import React, { useContext, useMemo } from 'react';
import flush from 'just-flush';
import { CompanyLogos } from '@stereoagency/ui';
import type { ILazyBlocksImage } from '@/types';
import { useMediaItems } from '@/hooks';
import { LinesContext } from '@/components/LinesContext';

type Props = {
  data: {
    label?: string
    title: string
    logos: {
      logo: ILazyBlocksImage
      label?: string
    }[]
  }
};

export default function NvisoCompanyLogos({ data: { label, title, logos } }: Props) {
  const { enableLines } = useContext(LinesContext);
  const mediaItemIds = useMemo(() => (
    flush(logos.map(({ logo }) => logo.id))
  ), [logos]);
  const mediaItems = useMediaItems(mediaItemIds);

  return (
    <CompanyLogos
      enableLines={enableLines}
      label={label}
      title={title}
      logos={flush(logos.map((item) => {
        const mediaItem = mediaItems.get(item.logo.id);

        if (!mediaItem) {
          return null;
        }

        return {
          label: item.label ?? undefined,
          logo: {
            src: mediaItem.mediaItemUrl ?? '',
            width: mediaItem.mediaDetails?.width ?? 120,
            height: mediaItem.mediaDetails?.height ?? 120,
          },
        };
      }))}
    />
  );
}
